// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-toimitilat-jsx": () => import("./../../../src/pages/toimitilat.jsx" /* webpackChunkName: "component---src-pages-toimitilat-jsx" */),
  "component---src-pages-turun-kauppapuisto-jsx": () => import("./../../../src/pages/turun-kauppapuisto.jsx" /* webpackChunkName: "component---src-pages-turun-kauppapuisto-jsx" */),
  "component---src-pages-yrityksen-tarina-jsx": () => import("./../../../src/pages/yrityksen-tarina.jsx" /* webpackChunkName: "component---src-pages-yrityksen-tarina-jsx" */)
}

